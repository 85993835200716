<template>
  <div class="contact-from-container">
    <form action="">
      <div class="contact-form-title">Your Name (required)</div>
      <input class="contact-form-inputBar" type="text" placeholder="Your Name" required />
      <div class="contact-form-title margintop21">Your Email (required)</div>
      <input
        class="contact-form-inputBar"
        type="text"
        placeholder="example@mail.com"
        required
      />
      <div class="contact-form-title margintop21">Subject</div>
      <input class="contact-form-inputBar" type="text" placeholder="Subject" />
      <div class="contact-form-title margintop21">Your Message</div>
      <input class="contact-form-inputBar" type="text" placeholder="Your Message" />
      <input class="contact-form-btn" type="submit" value="SEND" />
    </form>
  </div>
</template>

<script>
export default {
}

</script>

<style scoped>
.contact-form-title {
  font-size: 14px;
  font-weight: 400;
  color: #163257;
}
.margintop21 {
  margin-top: 15px;
}
.contact-from-container {
  background: #f5f5f5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 60px;
}
.contact-form-inputBar {
  margin-top: 3px;
  width: 100%;
  height: 56px;
  box-sizing: border-box;
  padding-left: 16px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  outline: none;
}
.contact-form-btn {
  width: 100%;
  height: 56px;
  background-color: #163257;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 600;
  color: #ffffff;
  margin-top: 32px;
}
@media only screen and (max-width: 600px) {
.contact-form-title {
  font-size: 1rem;
  font-weight: 400;
  padding-left: 2vw;
  color: #163257;
}
.margintop21 {
  margin-top: 3vw;
  margin-bottom: 1vw;
}
.contact-from-container {
  background: #f5f5f5;
  border-radius: 8px;
  box-sizing: border-box;
  padding: 5vw 4vw;
}
.contact-form-inputBar {
  margin-top: 3px;
  width: 100%;
  height: 7vh;
  box-sizing: border-box;
  padding-left: 16px;
  background: #ffffff;
  border-radius: 2px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  font-size: 16px;
  outline: none;
}
.contact-form-btn {
  width: 100%;
  height: 56px;
  background-color: #163257;
  border-radius: 2px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.3vw;
  color: #ffffff;
  margin-top: 5vw;
}
}
</style>
